import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { selectors } from "reducers";
import { Form, Formik } from "formik";
import { useFetch } from "hooks";
import { path } from "ramda";
import { get, put } from "utils/api";
import { formatDate, getApiErrorMessage } from "utils/misc";
import FormikField from "components/forms/formik-field";
import YesNoCheckbox from "./yes-no-checkbox";
import { Spinner } from "components/ui";
import { PatientProfileContext } from "components/profile/context";
import { invalidatePatientProfileInfo, fetchPatientProfileInfo } from "actions/patient";
import * as msg from "actions/message";
import "./../tabsStyles.css";
import moment from "moment";

const ComplianceData = ({
  patientId,
  patientInfo,
  complianceActive,
  displaySpinner,
  userRole,
  setIsCompliantForBanner,
  companyManagesCompliance,
  CompanyManagesResupply,
  reloadComplianceTab
}) => {
  const dispatch = useDispatch();
  const [isCompliant, setIsCompliant] = useState(false);
  const [complianceModified, setComplianceModified] = useState(false);
  const [complianceMetEditionAllowed, setComplianceMetEditionAllowed] =
    useState(false);
  const [hasStageComplianceAndIsActive, setHasStageComplianceAndIsActive] =
    useState(false);

  const { accessible_users } = useContext(PatientProfileContext);

  const {
    response: ComplianceInfo,
    fetchData,
    isFetching: fetchingComplianceInfo
  } = useFetch({
    apiFn: patientId => get(`patients/${patientId}/compliance-info`),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const {
    response: papTypes,
    fetchData: fetchPapTypes,
    isFetching: fetchingPapTypes
  } = useFetch({
    apiFn: () => get(`patients/PAPTypes`),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const {
    response: setupTypes,
    fetchData: fetchSetupTypes,
    isFetching: fetchingSetupTypes
  } = useFetch({
    apiFn: () => get(`patients/SetupTypes`),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  const {
    response: complianceSoftwares,
    fetchData: fetchComplianceSoftwares,
    isFetching: fetchingComplianceSoftware
  } = useFetch({
    apiFn: () => get(`patients/complianceSoftware`),
    defaultValue: [],
    transformError: path(["response", "body", "status"])
  });

  async function evaluateCall() {
    await fetchData(patientId);
    await fetchPapTypes();
    await fetchSetupTypes();
    await fetchComplianceSoftwares();
  }

  React.useEffect(() => {
    setIsCompliant(ComplianceInfo.compliant_met);

    if (ComplianceInfo) setIsCompliantForBanner(ComplianceInfo.compliant_met);
  }, [ComplianceInfo]);

  React.useEffect(() => {
    evaluateCall();
  }, [patientId, complianceActive]);

  React.useEffect(() => {
    if (reloadComplianceTab) evaluateCall();
  }, [reloadComplianceTab]);

  React.useEffect(() => {
    showValuesOnConsole();
  }, [ComplianceInfo]);

  React.useEffect(() => {
    if (patientInfo.active && patientInfo.stage_id == 2) {
      setHasStageComplianceAndIsActive(true);
    } else {
      setHasStageComplianceAndIsActive(false);
    }
  }, [patientInfo]);

  React.useEffect(() => {
    if (
      userRole == "Administrator" ||
      userRole == "CompanyAdministrator" ||
      userRole == "OfficeAdministrator"
    ) {
      setComplianceMetEditionAllowed(true);
    }
  }, []);

  const { fetchData: uploadComplianceChanges } = useFetch({
    apiFn: ({ patientId, values }) =>
      put(`patients/${patientId}/compliance-info`, values),
    defaultValue: null,
    transformError: path(["response", "body", "status"]),
    onSuccess: () => {
      evaluateCall();
    },
    onError: error => dispatch(msg.errorMessage(getApiErrorMessage(error)))
  });

  const [arrivingValues, setArrivingValues] = useState({
    pap_type: ComplianceInfo.pap_type,
    setup_type: ComplianceInfo.setup_type,
    compliance_coach: ComplianceInfo.compliance_coach,
    compliant_met: ComplianceInfo.compliant_met,
    compliance_percentage: ComplianceInfo.compliance_percentage,
    compliance_software: ComplianceInfo.compliance_software,
    task_id: ComplianceInfo.task_id,
    compliant_met_date: formatDate(
      ComplianceInfo.compliant_met_date,
      "YYYY-MM-DD"
    ),
    md_follow_up_date: formatDate(
      ComplianceInfo.md_follow_up_date,
      "YYYY-MM-DD"
    ),
    high_cai: ComplianceInfo.high_cai,
    high_ahi: ComplianceInfo.high_ahi,
    post_90_day_monitoring: ComplianceInfo.post_90_day_monitoring
  });

  const validateCompliantPercentage = compliancePercentage => {
    let errorMessage;
    if (
      compliancePercentage > 100 ||
      compliancePercentage < 0 ||
      isNaN(compliancePercentage) ||
      compliancePercentage === null
    ) {
      errorMessage = "Invalid amount. Value must be 0-100";
    }

    const compliantMet = formRef.current.values.compliant_met;
    if (compliantMet && compliancePercentage < 70) {
      errorMessage = "Invalid amount. Value must be greater or equal than 70";
    }
    if (!compliantMet && compliancePercentage >= 70) {
      errorMessage = "Invalid amount. Value must be smaller than 70";
    }

    return errorMessage;
  };

  function showValuesOnConsole() {
    setArrivingValues({
      ...arrivingValues,
      pap_type: ComplianceInfo.pap_type,
      setup_type: ComplianceInfo.setup_type,
      compliance_coach: ComplianceInfo.compliance_coach,
      compliant_met: ComplianceInfo.compliant_met,
      compliance_percentage: ComplianceInfo.compliance_percentage,
      compliance_software: ComplianceInfo.compliance_software,
      task_id: ComplianceInfo.task_id,
      compliant_met_date: formatDate(
        ComplianceInfo.compliant_met_date,
        "YYYY-MM-DD"
      ),
      md_follow_up_date: formatDate(
        ComplianceInfo.md_follow_up_date,
        "YYYY-MM-DD"
      ),
      post_90_day_monitoring: ComplianceInfo.post_90_day_monitoring,
      high_cai: ComplianceInfo.high_cai,
      high_ahi: ComplianceInfo.high_ahi
    });
  }

  const validateNullAndRequiredIfChanged = value => {
    let errorMessage;
    if (!value && complianceModified) {
      errorMessage = "You must select an option";
    }
    return errorMessage;
  };

  const validateMdFollowUpDate = value => {
    let errorMessage;
    const setUpDatePlus30Days = moment(patientInfo.setup_date).add(30, "days");
    const isValid = moment(value).isSameOrAfter(setUpDatePlus30Days);
    if (!isValid) {
      errorMessage = `You must select a date 30 days after set up date: ${formatDate(
        patientInfo.setup_date,
        "MM/DD/YYYY"
      )}`;
    }
    return errorMessage;
  };

  const validateComplianceMetDate = value => {
    let errorMessage;
    const setUpDatePlus21Days = moment(patientInfo.setup_date).add(21, "days");
    const isValid = moment(value).isSameOrAfter(setUpDatePlus21Days);
    if (!isValid) {
      errorMessage = `You must select a date 21 days after set up date: ${formatDate(
        patientInfo.setup_date,
        "MM/DD/YYYY"
      )}`;
    }
    return errorMessage;
  };

  const formRef = useRef(null);

  const compliantMetChange = value => {
    if (value === null) {
      formRef.current.setFieldValue("compliant_met_date", "");
      formRef.current.setFieldValue("task_id", "");
      formRef.current.setFieldValue("next_stage", "");
    }
    if (value === true) {
      formRef.current.setFieldValue("task_id", "");
    }
    if (value === false) {
      formRef.current.setFieldValue("next_stage", "");
    }
    formRef.current.setFieldValue("compliant_met", value);

    if (!formRef.current.values.compliance_percentage) {
      formRef.current.setTouched({ ["compliance_percentage"]: false }, true);
    }

    setComplianceModified(true);
  };

  return (
    <div className="special-fields">
      {!fetchingComplianceInfo &&
      !fetchingPapTypes &&
      !fetchingSetupTypes &&
      !fetchingComplianceSoftware &&
      !displaySpinner ? (
        <div>
          <Formik
            initialValues={{
              pap_type: arrivingValues.pap_type,
              setup_type: arrivingValues.setup_type,
              compliance_coach: arrivingValues.compliance_coach,
              compliant_met: arrivingValues.compliant_met,
              compliance_percentage: arrivingValues.compliance_percentage,
              compliance_software: arrivingValues.compliance_software,
              compliant_met_date: arrivingValues.compliant_met_date,
              md_follow_up_date: arrivingValues.md_follow_up_date,
              post_90_day_monitoring: arrivingValues.post_90_day_monitoring,
              high_cai: arrivingValues.high_cai,
              high_ahi: arrivingValues.high_ahi,
              next_stage: "",
              task_id: arrivingValues.task_id
            }}
            innerRef={formRef}
            enableReinitialize={true}
            onSubmit={async values => {
              await uploadComplianceChanges({ patientId, values })
                .then(() => {
                  dispatch(invalidatePatientProfileInfo({ patientId: patientId }));
                  dispatch(fetchPatientProfileInfo({ patientId: patientId }));
                });
            }}
          >
            {({ values, handleChange, isSubmitting }) => (
              <Form className="compliance-data-width">
                <FormikField
                  name="pap_type"
                  component="select"
                  label="PAP type"
                  validate={validateNullAndRequiredIfChanged}
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <option value={null}></option>
                  {papTypes
                    ? papTypes.map(({ Text, Value }) => (
                        <option key={Value} value={Value}>
                          {Text}
                        </option>
                      ))
                    : null}
                </FormikField>

                <FormikField
                  name="setup_type"
                  component="select"
                  label="Setup type"
                  validate={validateNullAndRequiredIfChanged}
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <option value={null}></option>
                  {setupTypes
                    ? setupTypes.map(({ Text, Value }) => (
                        <option key={Value} value={Value}>
                          {Text}
                        </option>
                      ))
                    : null}
                </FormikField>

                <FormikField
                  name="compliance_coach"
                  component="select"
                  label="Compliance Coach"
                  validate={validateNullAndRequiredIfChanged}
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <option value={null}></option>
                  {accessible_users?.compliance_coaches.map(x => (
                    <option key={x.key} value={x.value}>
                      {x.text}
                    </option>
                  ))}
                </FormikField>

                <YesNoCheckbox
                  defaultValue={values.compliant_met}
                  disabled={
                    isSubmitting ||
                    (isCompliant !== null && !complianceMetEditionAllowed) ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={compliantMetChange}
                  hasMinimumTherapyDays={
                    ComplianceInfo?.has_minimum_therapy_days
                  }
                  followUpDate={values.md_follow_up_date}
                  compliancePercentage={values.compliance_percentage}
                  setUpDate={patientInfo.setup_date}
                />

                <div style={{
                    display: values.compliant_met === false ? "unset" : "none"
                  }}>
                  <fieldset >
                    <legend>Compliance Not Met</legend>
                    <span>Would you like to deactivate the patient with the reason `Compliance Not Met` or leave the patient active?</span>
                    <FormikField
                          name="next_stage"
                          component="select"
                          validate={
                            values.compliant_met === false
                              ? validateNullAndRequiredIfChanged
                              : null
                          }
                          disabled={
                            isSubmitting ||
                            values.compliant_met === true ||
                            !hasStageComplianceAndIsActive
                          }
                          onChange={e => {
                            handleChange(e);
                          }}
                        >
                          <option value={null}></option>
                          <option value={"Inactivate"}>Inactivate</option>
                          <option value={"Remain Active"}>Remain Active</option>
                    </FormikField>
                    <span>Please select one of the following tasks if it applies, `Convert to Purchase` or `Pickup Equipment`.</span>
                    <FormikField
                      name="task_id"
                      component="select"
                      disabled={
                        isSubmitting ||
                        values.compliant_met !== false ||
                        (values.compliant_met === false && values.next_stage !== "Inactivate") ||
                        !hasStageComplianceAndIsActive
                      }
                      onChange={e => {
                        handleChange(e);
                      }}
                    >
                      <option key="" value={null}></option>
                      <option key="927e69c2-0d25-44ef-9bae-d3104e09a2d5" value="927e69c2-0d25-44ef-9bae-d3104e09a2d5">Convert to Purchase</option>
                      <option key="06e5ba1e-f771-4c6e-bcab-6d9e1838757f" value="06e5ba1e-f771-4c6e-bcab-6d9e1838757f">Pickup Equipment</option>
                    </FormikField>
                  </fieldset>
                </div>

                <div style={{
                    display: values.compliant_met === true && patientInfo.active ? "unset" : "none"
                  }}>
                  <fieldset >
                    <legend>Compliance Met</legend>
                    <span>{CompanyManagesResupply
                      ? "Would you like to deactivate the patient with the reason 'Compliance Met', move the patient to 'Resupply', or leave the patient active as 'Compliance'?"
                      : "Would you like to deactivate the patient with the reason 'Compliance Met' or leave the patient active?"}</span>
                    <FormikField
                          name="next_stage"
                          component="select"
                          validate={
                            values.compliant_met === true
                              ? validateNullAndRequiredIfChanged
                              : null
                          }
                          disabled={
                            isSubmitting ||
                            values.compliant_met !== true ||
                            !hasStageComplianceAndIsActive
                          }
                          onChange={e => {
                            handleChange(e);
                          }}
                        >
                          <option key="1" value=""></option>
                          {CompanyManagesResupply ? <option key="2" value={"Resupply"}>Resupply</option> : null}
                          <option key="3" value={"Remain Active"}>Remain Active</option>
                          <option key="4" value={"Inactivate"}>Inactivate</option>
                    </FormikField>
                  </fieldset>
                </div>

                <FormikField
                  name="compliant_met_date"
                  type="date"
                  label="Compliance Date Met"
                  validate={
                    values.compliant_met == true
                      ? validateComplianceMetDate
                      : null
                  }
                  style={{
                    display: values.compliant_met === null ? "none" : "unset"
                  }}
                  disabled={
                    isSubmitting ||
                    (isCompliant !== null && !complianceMetEditionAllowed) ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  min={moment(patientInfo.setup_date)
                    .add(21, "days")
                    .format("YYYY-MM-DD")}
                />

                <FormikField
                  name="compliance_percentage"
                  type="number"
                  label="Compliance Percentage"
                  validate={
                    values.compliant_met !== null
                      ? validateCompliantPercentage
                      : null
                  }
                  disabled={
                    isSubmitting ||
                    (isCompliant !== null && !complianceMetEditionAllowed) ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                />

                <FormikField
                  name="md_follow_up_date"
                  type="date"
                  label="MD Follow Up Date"
                  validate={
                    values.compliant_met == true ? validateMdFollowUpDate : null
                  }
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  min={moment(patientInfo.setup_date)
                    .add(30, "days")
                    .format("YYYY-MM-DD")}
                />

                <FormikField
                  name="compliance_software"
                  component="select"
                  label="Compliance Software"
                  validate={validateNullAndRequiredIfChanged}
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <option value={null}></option>
                  {complianceSoftwares
                    ? complianceSoftwares.map(({ Text, Value }) => (
                        <option key={Value} value={Value}>
                          {Text}
                        </option>
                      ))
                    : null}
                </FormikField>

                <FormikField
                  name="high_cai"
                  type="checkbox"
                  label="High CAI"
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  checked={values.high_cai}
                  className={"form-field-inline"}
                  inlineError={true}
                />

                <FormikField
                  name="high_ahi"
                  type="checkbox"
                  label="High AHI"
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  checked={values.high_ahi}
                  className={"form-field-inline"}
                  inlineError={true}
                />

                <FormikField
                  name="post_90_day_monitoring"
                  type="checkbox"
                  label="Post 90 Day Monitoring"
                  disabled={
                    isSubmitting ||
                    !companyManagesCompliance ||
                    !hasStageComplianceAndIsActive
                  }
                  onChange={e => {
                    handleChange(e);
                  }}
                  checked={values.post_90_day_monitoring}
                  className={"form-field-inline"}
                  inlineError={true}
                />

                <div className="submit-button-container">
                  <button
                    className="submit-button"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !companyManagesCompliance ||
                      !hasStageComplianceAndIsActive
                    }
                  >
                    Submit
                  </button>
                  {isSubmitting && <Spinner />}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

ComplianceData.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  displaySpinner: PropTypes.bool.isRequired,
  companyManagesCompliance: PropTypes.bool,
  CompanyManagesResupply: PropTypes.bool,
  setIsCompliantForBanner: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  complianceActive: PropTypes.bool,
  reloadComplianceTab: PropTypes.bool
};

export default connect(state => ({
  userRole: selectors.getUserRole(state)
}))(ComplianceData);
